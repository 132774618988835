<template>
	<div :class="themeClass" class="payforCont width-fill margin-t-10 flex-column bg-fff">
		<div class="title font-size18 font-weight700 margin-l-10">设置支付密码</div>
		<div class="width-fill flex-column-center-center">
			<el-form :model="formData" :rules="rules" ref="formData" label-width="140px" class="demo-ruleForm" v-loading='formLoading'>
				<div class="text-center font-size16 margin-b-25 flex-row-center-center">
					<span class="iconfont icon-biaodan xinzengIcon color-theme font-size25"></span>
					设置支付密码
				</div>
				<el-form-item label="手机号">
					<el-input :value="getUserInfo.phone" autocomplete='off' disabled />
				</el-form-item>
				<el-form-item label="新密码" prop="password">
					<el-input v-model="formData.password" maxlength='6'  autocomplete='new-password' disableautocomplete  :type="passShow?'text':'password'" placeholder="请输入6位数字支付密码">
						<i slot="suffix" class="iconfont pointer" :class="passShow?'icon-eye':'icon-yanjing1'" @click="showPass(1)"></i>
					</el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="rePassword">
					<el-input v-model="formData.rePassword" maxlength='6' :type="passShow1?'text':'password'" placeholder="请再次确认密码">
						<i slot="suffix" class="iconfont pointer" :class="passShow1?'icon-eye':'icon-yanjing1'" @click="showPass(2)"></i>
					</el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="Perfectregister" style="position: relative;">
					<div v-show="innerVisibles" style="position: absolute;bottom: 0px;z-index: 999; padding: 3px; background-color: #fff; left: 0;">
						<i class="el-icon-circle-close" style="position: absolute;right: -20px;top: -20px;color: #0173FE;font-size:26px;"
						 @click="closeYanzheng"></i>
						<setGraphic @messageData='messageData'></setGraphic>
					</div>
					<el-input v-model="formData.Perfectregister" placeholder="输入验证码">
						<template slot="append">
							<div @click="getCode" v-if="verCodeText" class="pointer" style="color: #0173fe;">获取验证码</div>
							<div v-if="!verCodeText" style="color: #0173fe">重发验证（{{verCodeNub}}）秒</div>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<div class="background-color-theme button-change-pay-pass pointer flex-row-center-center" @click="submitInform()">确定</div>
				</el-form-item>
			</el-form>
		</div>
	</div>

</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import setGraphic from '@/components/setGraphic';

	export default {
		data() {
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.formData.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				verCodeText: true, // 是否显示验证码字段
				verCodeNub: "", // 验证码读数
				verCodeTimer: null, // 验证码计时器变量
				formLoading: false, //表单加载
				passShow: false,
				passShow1: false,
				formData: {},
				innerVisibles: false,
				msg: false,
				rules: {
					password: [{
						required: true,
						message: '请输新密码',
						trigger: 'blur'
					},
					{
					  pattern: /^[0-9]*$/,
					  message: '密码必须为6位数字',
					  trigger: 'blur'
					}
					],
					rePassword: [{
						required: true,
						message: '请再次输入密码',
						trigger: 'blur'
					}, {
						validator: validatePass2,
						trigger: 'blur'
					}, 
					{
					  pattern: /^[0-9]*$/,
					  message: '密码必须为6位数字',
					  trigger: 'blur'
					}
					],
					Perfectregister: [{
						required: true,
						message: '请输验证码',
						trigger: 'blur'
					}],
					type: '' //是1 返回待支付
				},
			};
		},
		computed: {
			...mapGetters(["getThemeName", 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		created() {},
		mounted() {
			this.type = this.$route.query.type
		},
		components: {
			setGraphic,
		},
		methods: {
			//接收验证码插件传来的值
			messageData(a) {
				if (a) {
					var _this = this;
					setTimeout(function() {
						_this.innerVisibles = false;
						_this.msg = a
						_this.getCode();
						console.log('子传递值，已经变成true')
					}, 500);
				}
			},
			//表单验证
			submitInform() {
				this.$refs.formData.validate((valid) => {
					if (valid) {
						this.Auditing();
					} else {
						return false;
					}
				});
			},
			closeYanzheng() {
				this.innerVisibles = false;
			},

			// 获取验证码
			getCode() {
				if (this.msg == false) {
					this.innerVisibles = true;
					return;
				}
				let _this = this;
				const TIME_COUNT = 60;
				if (!_this.verCodeTimer) {
					// TODO 临时用接口
					_this.ApiRequestPost("api/mall/ebcustomer/baseinfo/reset-walletpwd-msg").then(
						(res) => {
							console.log(res)
							if(res.code == "HengHeCode1000"){
								// _this.tipsSuccess("验证码发送成功");
								_this.verCodeNub = TIME_COUNT;
								_this.verCodeText = false;
								_this.verCodeTimer = setInterval(() => {
									if (_this.verCodeNub > 0 && _this.verCodeNub <= TIME_COUNT) {
										_this.verCodeNub--;
									} else {
										_this.verCodeText = true;
										clearInterval(_this.verCodeTimer);
										_this.verCodeTimer = null;
										_this.msg = false
									}
								}, 1000);
							}else{
								this.tipsInfo('操作频繁，请稍等再试');
								this.msg = false
							}
						},
						(rej) => {
							this.tipsInfo('操作频繁，请稍等再试');
							this.msg = false
						}
					);
				}
			},
			//确定修改
			Auditing() {
				this.ApiRequestPost('api/mall/ebcustomer/baseinfo/reset-walletpwd', {
					pwd: this.formData.password,
					pwdConfirm: this.formData.rePassword,
					msgCode: this.formData.Perfectregister
				}).then(
					res => {
						console.log(res);
						if (res.code == 'HengHeCode1000') {
							//this.$router.go(-1)
							this.$message({
								type: 'success',
								message: res.message,
							});
							let that = this
							if (this.type == 1) {
								setTimeout(() => {
									that.$router.replace({
										path: '/businessme/PaymentOrderList',
									});
								}, 600);
							}
						}
					},
					error => {}
				);
			},
			//密码可视
			showPass(index) {
				if (index == 1) {
					this.passShow = !this.passShow;
				} else {
					this.passShow1 = !this.passShow1;
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}

		color: #FFFFFF;
	}

	.button-change-pay-pass {
		border-radius: 2px;
		width: 120px;
		height: 40px;
	}

	.title {
		height: 24px;
		line-height: 24px;
		margin-top: 8px;
	}

	.payforCont {
		padding-bottom: 40px;
	}

	.demo-ruleForm {
		width: 472px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	::v-deep .el-input-group__append {
		border-radius: 0 !important;
		border-right: 1px solid #F2F2F2;
		border-top: 1px solid #F2F2F2;
		border-bottom: 1px solid #F2F2F2;
		background-color: #FFFFFF;
		min-width: 70px;
		text-align: center;
	}

	.phone-box {
		margin-top: 5px;
		padding: 0 15px;
		height: 30px;
		line-height: 30px;
		border: 1px solid #f2f2f2;
		border-radius: 4px;
	}
</style>
